.top-toolbar .toolbar-amount, .top-toolbar .pages {
    display: none;
}

.bottom-toolbar .toolbar-amount, .bottom-toolbar .sorter, .bottom-toolbar .limiter {
    display: none;
}

.bottom-toolbar .row {
    max-width:100%;
}
